import * as React from 'react'

import styles from './CategoryHeader.module.scss'

interface Props {
  title: string
  description: string
}

const CategoryHeader = ({ title, description }: Props) => (
  <div className={styles.categoryHeader}>
    {title && <h1>{title}</h1>}
    {description && <p>{description}</p>}
  </div>
)

export default CategoryHeader
