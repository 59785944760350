import * as React from 'react'
import t from 'typy'

import { getProductsByIds } from 'src/utils/product'
import ProductGrid from 'src/components/ProductGrid/ProductGrid'
import CategoryHeader from 'src/components/Category/CategoryHeader'
import SEO from 'src/components/SEO/SEO'

interface Props {
  pageContext: any
}

const Category = ({ pageContext }: Props) => {
  const { category } = pageContext
  const products = getProductsByIds(category.products)
  const description = t(category, 'metaDescription').safeObject

  return (
    <div>
      <SEO seoMetaDescription={description} seoTitle={category.name} />
      <div className="container">
        <CategoryHeader title={category.name} description={description} />
      </div>
      <ProductGrid products={products} />
    </div>
  )
}

export default Category
